/* eslint-disable max-len */
export default {
  bannerText: 'Upgrade to our new app and transfer your TimmyMe™ balance',
  getIOSapp: 'Get our iOS app',
  getAndroidapp: 'Get our Android app',
  viewTimCards: 'Or view your TimCard® cards on the web',
  loginText: 'TimmyMe™ is no longer supported. To check your balances, visit the <a href="https://timcard.prepaidaccess.com/consumer?locale=en_CA">TimCard<sup>®</sup> website</a>.<br /><br /> Download the latest app to earn rewards!',
  username: 'Username',
  password: 'Password',
  login: 'Log In',
  loginFeedback: 'Username or password not valid',
  forgotPassword: 'Forgot password?',
  timmyMeHeader: 'My TimmyMe™ gift cards',
  back: 'Back',
  showBarcode: 'Show barcode at register',
  showBarcodeInstructions: 'You can show this barcode on your mobile phone to “Scan to Pay” for purchases, print this barcode to “Scan to Pay” for purchases, or print and provide this barcode to exchange for a physical TimCard® in-restaurant. Please remember to keep your barcode secure.',
};
