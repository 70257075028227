export default {
  privacyPolicy() {
    return `https://timhortons.com/ca/${this.locale}/privacy.php`;
  },
  termsAndConditions() {
    return `https://timhortons.com/ca/${this.locale}/legal.php`;
  },
  isOneOfTimmyMePages() {
    const timmyMeAppPages = ['downloadapp', 'login', 'usertimmymecards', 'timmymecard'];
    const isOneOfTimmyMePages = timmyMeAppPages.some(page => (
      window.location.pathname.indexOf(page) > -1
    ));

    if (window.location.pathname.indexOf('downloadapp') > -1) {
      // eslint-disable-next-line max-len
      document.body.style.backgroundImage = 'radial-gradient(circle at 53% 50%, #de1f31, #a11427)';
      return isOneOfTimmyMePages;
    }

    document.body.style.backgroundColor = '#f4f2eb';
    return isOneOfTimmyMePages;
  },
  userTimmyMeCards() {
    const userTimmyMeCards = window.localStorage.getItem('THTimmyMeCards');
    if (userTimmyMeCards) {
      return JSON.parse(userTimmyMeCards);
    }
    return [];
  },
};
