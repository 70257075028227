/* eslint-disable max-len */
export default [
  {
    id: 'about-the-app',
    header: 'About the App',
    content: [
      {
        question: 'What is the Tim Hortons<sup>®</sup> app?',
        answer: 'The Tim Hortons<sup>®</sup> app makes it easy to order and pay. Select and customize your favorite food and drinks, choose your preferred participating Tim Hortons<sup>®</sup> location, and pay — all from the app! If you\'re already at a participating Tim Hortons<sup>®</sup> location, just scan the barcode on your phone to pay. It’s everything you love about Tim Hortons<sup>®</sup>  — now easier, faster, and more personal.',
      },
      {
        question: 'What are the benefits of the Tim Hortons<sup>®</sup> app?',
        answer: 'It’s ordering made easy. Save time by ordering on your phone whenever, wherever you like, scan to pay in participating restaurants, and more! We are constantly updating the app with new features — stay tuned!',
      },
      {
        question: 'How does the Tims<sup>™</sup> Rewards program work?',
        answer: 'The Tim Hortons<sup>®</sup> app makes it simple to earn and spend rewards. Find out more at <a href="https://timhortons.com/rewards">timhortons.com/rewards</a>.',
      },
    ],
  },
  {
    id: 'download-the-app',
    header: 'Download the App',
    content: [
      {
        question: 'How can I download the Tim Hortons<sup>®</sup> app?',
        answer: 'Instructions by device type:',
        list: [
          'iOS: Go to the Apple App Store on your mobile device, search, and select “Tim Hortons.” Tap “Get,” followed by “Install,” and enter your device password (or Touch ID, if available on your device).',
          'Android<sup>™</sup>: Go to the Google Play Store on your mobile device, search “Tim Hortons,” select it, and tap “Install.”',
        ],
        numbered: false,
      },
    ],
  },
  {
    id: 'set-up-an-account',
    header: 'Set Up an Account',
    content: [
      {
        question: 'How do I set up an account for the Tim Hortons<sup>®</sup> app?',
        answer: 'Creating an account is quick and easy. Follow these easy steps when you first download the app or after you’ve selected menu items for your first order:',
        list: [
          '“Log in to your account” by choosing to do so with Facebook, Google or your email address, and follow the steps prompted in the app.',
          'Select your payment method (credit/debit card or Apple Pay) in order to load a balance onto your digital Tim Hortons<sup>®</sup>  account within the app, which will be used to pay for all orders. Your app balance will reload with your confirmation when it’s insufficient to complete an order. The app will either automatically load a minimum of $10 (CAD for Guests in Canada, or USD for Guests in the U.S.) from your credit/debit card/ Apple Pay account. See the explanation below on how to connect these payment methods',
          'You may now start ordering!',
        ],
        numbered: false,
      },
      {
        question: 'Where is my account number listed in the Tim Hortons<sup>®</sup> app?',
        answer: 'Tap the red “Scan to Pay” at the bottom of the Menu Screen before you select menu items. Your 16-digit account number is listed below the barcode.',
      },
    ],
  },
  {
    id: 'transfer-timmy-me',
    header: 'Transfer TimCard<sup>®</sup>',
    content: [
      {
        question: 'Will I still be able to access the TimCard<sup>®</sup> website?',
        answer: 'Yes. You can still manage your TimCard<sup>®</sup> balance and auto reload through the <a href="https://timcard.prepaidaccess.com/consumer?locale=en_CA">TimCard<sup>®</sup> website</a>, which is unaffected by these changes.',
      },
      {
        question: 'How many TimCard<sup>®</sup> gift cards can I transfer to the Tim Hortons<sup>®</sup> Mobile App?',
        answer: 'Our newer App currently supports only one TimCard<sup>®</sup> per account. You can transfer up to five TimCard<sup>®</sup> balances.',
      },
      {
        question: 'Can I set up auto reload for my account on the new Tim Hortons<sup>®</sup> Mobile App?',
        answer: 'At this time, our newer App does not allow users to automatically reload their account balances. However, should you wish to place an order through the newer App that costs more than your current balance, the App will prompt you to reload your account to complete the transaction.',
      },
      {
        question: 'How do I discontinue my auto reload?',
        answer: 'To disable your auto reload, please follow the steps below:',
        list: [
          'Log into the <a href="https://timcard.prepaidaccess.com/consumer?locale=en_CA">TimCard<sup>®</sup> website</a>.',
          'Under “Tim Hortons Tim Cards” select the “My Tim Cards” icon to review your registered TimCard<sup>®</sup> gift cards.',
          'To adjust the auto reload settings for one of these TimCard<sup>®</sup> gift cards, select the “Auto Reload” icon below the TimCard<sup>®</sup> and then select “Suspend” to disable the auto reload feature.',
        ],
        secondaryAnswer: '<b>Note:</b> Please disable the feature within 24 hours of the next scheduled reload. Once the dollar value is loaded the transaction cannot be reversed.',
      },
      {
        question: 'Are there any funding or spending limits in the new Tim Hortons<sup>®</sup> Mobile App?',
        answer: 'Yes. The new App requires that you load a minimum balance of $5 when you place your first order. The maximum total value for your account is subject to the TimCard<sup>®</sup> registered within the newer App, but most TimCard<sup>®</sup> balances cannot exceed $100. As an additional security measure to minimize fraudulent activity, you cannot reload more than $50 per week to your in-app balance using a credit card. Any account reload that would bring your balance above $100, or reload using a credit card that exceeds the weekly $50 limit, will not be processed.<br><br>For example, if your balance is $80 and you try to reload $50 using a credit card, it will not be processed as your balance would exceed the $100 maximum. However, you may load $20 using a credit card to bring the account value up to the $100 maximum. ',
      },
      {
        question: 'Who can I contact with additional questions?',
        answer: 'Please feel free to reach out to the Mobile App team at any time using <a href="https://timhortonsapp.com/feedback.html">this feedback form</a>. We’re happy to answer any additional questions you may have.',
      },
    ],
  },
  {
    id: 'find-location',
    header: 'Find a Tim Hortons<sup>®</sup> Location with Mobile Order and Pay',
    content: [
      {
        question: 'Where can I use the Tim Hortons<sup>®</sup> app for mobile order and pay?',
        ansewr: 'Use the Tim Hortons<sup>®</sup> app at participating Tim Hortons<sup>®</sup> locations. View participating locations by tapping “Order Now” from the Home page and zooming in or out on the map to your desired location. Tap the checkmark on the location from which you’d like to order.',
      },
      {
        question: 'Do all Tim Hortons<sup>®</sup> locations accept mobile order and pay?',
        answer: 'Not all Tim Hortons<sup>®</sup> locations accept mobile ordering and pay at this time, but you can find participating locations nearby using the instructions above.',
      },
      {
        question: 'How do I find the Tim Hortons<sup>®</sup> location nearest me?',
        answer: 'Make sure location services are enabled within your device settings. Before starting to order, you will be shown the locations nearby that accept mobile ordering and payment on the “Select Restaurant” page. If you want to use the app without location settings turned on, you may select a location by using the map to manually locate your area on the map and select a participating location near you.',
      },
      {
        question: 'What should I do if I don’t see the location I want to order from?',
        answer: 'Zoom out on the map in order to see a greater variety of location options. Tap the checkmark on the location from which you would like to order. If you don’t see a participating location you know of nearby, try closing the app and re-opening to refresh locations.',
      },
    ],
  },
  {
    id: 'place-an-order',
    header: 'Place an Order',
    content: [
      {
        question: 'How do I select items from the menu?',
        answer: 'Tap on an item from the menu. Select desired modifications on the next screen, and tap the red “Add to Order” button at the bottom of the screen. Then, you’ll be brought back to the menu screen. To add additional menu items or order multiple quantities of the same item, just repeat these steps.',
      },
      {
        question: 'How do I edit an order or remove menu items from my cart before completing my order?',
        answer: 'Instructions by device type:',
        list: [
          'iOS: From the “My Order” page, swipe left on an order. You will then see a red button that says “Remove” and a grey button that says “Edit.” Tap “Add items” at the top right of the screen to go back to the menu and select more.',
          'Android: From the “My Order” or “Checkout” page, tap the three dots next to an order item. Then, tap “Remove” or “Edit” or tap “+” to go back to the menu to add more items.',
        ],
        numbered: false,
      },
      {
        question: 'How do I place a mobile order?',
        answer: 'Once you’ve finalized your menu selections, tap the “View Order” button at the bottom of the screen, and once you’ve done so, tap “Continue” on the “My Order” page. If this is your first order, and you don’t have Apple Pay setup or you haven’t added a method of payment, you’ll be prompted to do so. Then, “Place Order” or “Load and Place Order” to complete!',
      },
      {
        question: 'What should I do if I am having issues using my Tim Hortons<sup>®</sup> app?',
        answer: 'If your Tim Hortons<sup>®</sup> app is not working, you may be using an outdated version that is no longer supported. Please download the latest version by going to your app store and accepting the latest update. If issues persist, please <a href="./feedback.html">contact us</a>.',
      },
      {
        question: 'What should I do if I get an order error or failure message?',
        answer: 'Close the app to clear your order and try again. You will not be charged for any orders that fail. If the issue persists, please <a href="./feedback.html">contact us</a> with the following information, and we’ll look into it:',
        list: [
          'The email associated with your Tim Hortons<sup>®</sup> app account',
          'The Tim Hortons<sup>®</sup> location associated with your attempted order',
          'The date and time order of your attempted order',
          'The service mode you selected: Drive Thru, Take Out or Dine In',
        ],
        numbered: false,
      },
    ],
  },
  {
    id: 'add-to-my-order',
    header: 'Add to My Order',
    content: [
      {
        question: 'Can I add additional menu items to my mobile order at the counter or drive thru?',
        answer: 'If you would like to add items and pay for them via your app you can place an additional separate mobile order when you are at the restaurant after you tap “I’m Here” on your first order. If you would like to pay in-person, you can place another order with an employee at the counter or via the drive thru separately using Scan to Pay, a credit/debit card, a physical TimCard<sup>®</sup>, or cash.',
      },
    ],
  },
  {
    id: 'pay-for-order',
    header: 'Pay for My Order',
    content: [
      {
        question: 'How do I connect my credit/debit card to my Tim Hortons<sup>®</sup> mobile app to load or reload my balance?',
        answer: 'You’ll be prompted to add a method of payment upon placing your very first order using the app. Once you’ve made your selection(s) from the Tim Hortons<sup>®</sup> menu, tap “View Order,” “Continue,” “Confirm Location,” and select how you will be picking up your order. Then select your payment method to load a balance onto your Tim Hortons<sup>®</sup> app balance; such payment methods include Apple Pay or credit/debit card. If you select credit/debit card payment, enter your credit/debit card number, which will be saved within the app for future use and for automatically reloading your balance within the Tim Hortons<sup>®</sup> app.',
      },
      {
        question: 'How does the automatic reload function work?',
        answer: 'After you have set up your initial digital Tim Hortons<sup>®</sup>  account balance in the Tim Hortons<sup>®</sup> app, your payment method will be saved for future use. Once your Tim Hortons<sup>®</sup> app balance falls below the amount needed to complete your order, you will be prompted to reload your account balance in the selected reload amount.',
      },
      {
        question: 'How can I change the reload amount I am charged?',
        answer: 'The reload amount in the Tim Hortons<sup>®</sup> app is set at the default of $10, but can be set at increments of $10, $25, $50 or $100. If you want to change the default reload amount in your Tim Hortons<sup>®</sup> app, tap the gear in the top left corner of the screen to open “Settings,” then tap “My Balance,” and select a balance reload amount. On iOS, you must tap “Save” in the top right to set your default reload amount.',
      },
      {
        question: 'What is Scan to Pay, and how do I use it?',
        answer: 'Scan to Pay is an easy and convenient way to pay for your order at a Tim Hortons<sup>®</sup> counter or drive thru. After you are logged into the Tim Hortons<sup>®</sup> app, you can access the “Scan to Pay” from the Home page. When you tap “Scan to Pay,” the barcode can be scanned at a register to complete payment for the order. Your order total will be deducted from your Tim Hortons<sup>®</sup> app balance. If your balance is insufficient to complete the order, the app will auto-reload your account with your pre-selected amount (minimum $10) or you may tap the gear in the top left corner of the app to access “Settings,” then tap the green checkmark next to a balance reload amount and tap “Save” to add that amount to your Tim Hortons<sup>®</sup> app balance.',
      },
      {
        question: 'How do I connect Apple Pay or Android<sup>™</sup> Pay to my Tim Hortons<sup>®</sup> mobile app?',
        list: [
          'Apple Pay: If you have Apple Pay already set up on your device, it will automatically be designated as your preferred method of payment. You will, however, be able to add a different credit/debit card by tapping the gear icon in the top left of your screen to access “Settings,” then “Payment Methods.” If you do not have Apple Pay setup on your device, you add it by accessing the Wallet app. After doing so, refresh your Tim Hortons<sup>®</sup> app, and your use of Apple Pay will be enabled; check “Settings” and “Payment Methods” to view or change your default method of payment.',
          'Android Pay: At this time, Android Pay is not supported',
        ],
        numbered: false,
      },
      {
        question: 'What should I do if I don’t see my previous balance in the app?',
        answer: 'Make sure you are signed into the app with the correct account credentials. For example, if you logged in using Facebook the first time you used the app, continue to log in with Facebook each time you use the app. To view your balance once logged in, tap the gear icon in the top left of the app to access “Settings” and then tap “My Balance.”',
      },
      {
        question: 'Can I place a mobile order, but pay for it in cash?',
        answer: 'No, all mobile orders must be made via reloads to your Tim Hortons<sup>®</sup> account balance in the app.',
      },
      {
        question: 'Can I apply coupons to my mobile orders?',
        answer: 'The Tim Hortons<sup>®</sup> app will offer special promotions for mobile orders!',
      },
      {
        question: 'How do I request a refund on a mobile order?',
        answer: 'You may receive a refund on a mobile order via one of the following options:',
        list: [
          'In restaurant: Bring your mobile device to a Tim Hortons<sup>®</sup> restaurant manager. Show the manager your mobile order by tapping the “Settings” gear icon in the top left corner of the screen. Then tap “Order History” to view your orders by date, location and dollar amount. After verifying, they will be able to issue a refund back to your Tim Hortons<sup>®</sup> app balance via the Scan-To-Pay feature in the app, as appropriate.',
          '<a href="./feedback.html">Contact us</a>',
        ],
      },
      {
        question: 'How do I request a refund on a Tim Hortons<sup>®</sup> app reload?',
        answer: 'If you believe you were mistakenly charged for an account balance reload, please <a href="./feedback.html">contact us</a> with the following information, as possible, and we’ll look into it:',
        list: [
          'The specific issue experienced (where you got stuck, error message, etc.)',
          'The Tim Hortons<sup>®</sup> location from which you tried to order',
          'The email associated with your Tim Hortons<sup>®</sup> app account',
          'The payment method and name on credit/debit card',
          'The amount of reload',
        ],
      },
      {
        question: 'What should I do if the price on the app is different from the price listed in the Tim Hortons<sup>®</sup> location where my order has been placed?',
        answer: 'Please contact us, and include the Tim Hortons<sup>®</sup> location address, the item(s) you purchased, and the price on the app vs. the price in the location, and we’ll look into it. Prices for orders placed in Canada are shown in Canadian dollars. Prices for orders placed in the United States are shown in U.S. dollars.',
      },
    ],
  },
  {
    id: 'pickup-my-order',
    header: 'Pick Up My Order',
    content: [
      {
        question: 'Where do I pick up my mobile order?',
        answer: 'For Take Out or Dine In, head to the “Mobile Pickup” sign above the counter. Different Tim Hortons<sup>®</sup> locations may have different mobile order pickup locations. For Drive Thru orders, tell the employee at the speaker box that you placed a mobile order, give them your name, and they’ll retrieve it for you!',
      },
      {
        question: 'What do I do if I placed a mobile order, but it wasn’t received by the Tim Hortons<sup>®</sup> location I selected?',
        answer: 'Show an employee your mobile order by tapping the “Settings” gear icon in the top left corner of the screen. Then tap “Order History” to view your orders by date, location and dollar amount. After verifying, a Tim Hortons<sup>®</sup> employee will prepare your order.',
      },
      {
        question: 'What should I do if I selected Drive Thru when I placed my order, but I get to the Tim Hortons<sup>®</sup> location, and I want to Take Out or Dine In?',
        answer: 'Enter the Tim Hortons<sup>®</sup> location and notify an employee that you placed a mobile Drive Thru order but would like to Take Out or Dine In instead. At that time, the employee will prepare the order and deliver to you at the mobile order pick-up station inside.',
      },
    ],
  },
  {
    id: 'view-my-order',
    header: 'View My Order History',
    content: [
      {
        question: 'How do I view my placed orders?',
        answer: 'In your Tim Hortons<sup>®</sup> app, tap the gear icon in the top left of the screen to open “Settings,” then tap “Order History,” to view your orders by date, location, and purchase amount.',
      },
    ],
  },
  {
    id: 'edit-notifications',
    header: 'Edit Access/ Notifications',
    content: [
      {
        question: 'Does the Tim Hortons<sup>®</sup> app access my location?',
        answer: 'If you enable location services or select a Tim Hortons<sup>®</sup> location, the app uses your location only while you have the app open to find nearby participating restaurants, quickly ready your mobile orders for when you arrive, and send you special offers. You can turn this feature on or off at anytime in your app’s “Settings.”',
      },
      {
        question: 'How do I turn the location settings for the Tim Hortons<sup>®</sup> app on/off?',
        answer: 'Instructions by device type:',
        list: [
          'iOS: Go to your iPhone’s Settings App, scroll down the page until you see the icon for Tim Hortons<sup>®</sup> app icon, and tap the app icon, tap “Location,” and select the setting you desire.',
          'Android: Go to “Settings,” tap “Location access,” and select the setting you desire.',
        ],
        numbered: false,
      },
      {
        question: 'Why does the Tim Hortons<sup>®</sup> app send me notifications?',
        answer: 'Notifications may include alerts, sounds, and icon badges to inform you when your order is being prepared or to let you know about the latest Tim Hortons<sup>®</sup> promotions. You can manage such notifications by tapping “Settings,” tapping “Communication Preferences,” and checking or unchecking from the categories listed and clicking “Save.” Note: You will still be notified of information on your account such as account balance reloads.',
      },
      {
        question: 'How can I unsubscribe from mobile notifications, text messages, and/or email alerts that do not pertain to my mobile orders or account balance?',
        answer: 'Instructions by device type:',
        list: [
          'iOS: Tap the iPhone’s Settings App, scroll down, tap Notifications, tap on the Tim Hortons<sup>®</sup> app. From there, toggle “Allow Notifications” to “On” or “Off” as desired.',
          'Android: Tap Android Settings, tap Notifications, scroll down and select the Tim Hortons<sup>®</sup> app, change the settings as desired or check “Block All” to turn off all notifications.',
        ],
      },
    ],
  },
  {
    id: 'login-email-only',
    header: 'Log in with Email Only',
    content: [
      {
        question: 'Why is the Tim Hortons app getting rid of Facebook and Google logins?',
        answer: 'As an effort to give our users a more seamless experience and an even more secure app, we consolidated all accounts under the same email address to reduce points of entry and to give users one spot to hold all their information.',
      },
      {
        question: 'What will happen to my TimCard balance, Tims Rewards etc.?',
        answer: 'Starting at the beginning of November, if you had multiple accounts sharing the same email address you will find all your accounts’ information including balances and rewards by logging in with email.',
      },
      {
        question: 'I have a Facebook/Google app account and have just logged in with email but do not see my data transferred. How come?',
        answer: 'The transfer of your Facebook/Google account information (TimCard balances, Rewards, etc.) will only occur at the beginning of November. Until then, all data will still be displayed in the original account.',
      },
      {
        question: 'Until when can I use my Facebook and Google to log in?',
        answer: 'You can continue to use your Facebook and Google logins until the beginning of November, where we will begin to merge the accounts towards one single email account. After your account is migrated, you will be asked to log out and log back in with your email account associated with your Facebook or Google account.',
      },
      {
        question: 'I usually log in with email. What do I do now?',
        answer: 'Continue to log in with email. You won’t experience any changes.',
      },
      {
        question: 'I usually log in with Google/Facebook. What do I do now?',
        answer: 'Starting at the beginning of November, in order to log into the app you will be required to log in with your email address. The email address you should be using is the email address associated to your Facebook or Google account. If you are unsure what email address this is, please check your inboxes – we have sent that email address a confirmation message. If you would like to check your email associated to your account, it can be found under Settings>Profile.',
      },
      {
        question: 'How do I log in with email?',
        answer: 'On the app, click “Login with Email”. Enter in your email address (this should be the email address associated to your Facebook/Google account). You will then be emailed a 6 digit verification code to enter into the app. That’s it!',
      },
      {
        question: 'Can I change the email associated with my account?',
        answer: 'No, your email will continue to be used after this migration in order to keep your information within one account.',
      },
      {
        question: 'I don’t know the password to the email address I’m being told to use to get my verification code. What do I do?',
        answer: 'If you do not know the password to your email account, please follow the “Forgot your password?” steps given by your inbox service to reset your password or email the email provider to reset your password.',
      },
      {
        question: 'I don’t have access to the email account associated with mobile app login. What do I do?',
        answer: 'Please create a new mobile app account with an email address you have access to. Once November, please contact <a href="https://global.knowledgeforce.com/casemanagement/form/timhortons/" target="_blank"> Guest Services </a> to move your rewards/visits and transfer the balance of funds, if applicable, to your new mobile app account.',
        secondaryAnswer: 'You will need to provide the old email address and new email address in order to validate account ownership. Please note that your order history and your physical TimsRewards card will not be transferred. Only the rewards/visits and money can be moved.',
      },
      {
        question: 'Why is my physical Tims Rewards Card no longer working?',
        answer: 'As a result of the account transferring, your physical card may have been replaced by a digital Tims Rewards card. Please check the app under “Settings” > “Tims Rewards” to see if your card digits match. You can continue to use the digital Tims Rewards card in the app. If not, you can request for a new physical card in-restaurant, and then register it on the app.',
      },
      {
        question: 'Why did my TimCard balance amount increase?',
        answer: 'If your TimCard balance has gone up, it is because we found additional TimCard balance on another app account under your email address, and transferred it to your active account. Good news! You now have more money on your TimCard!',
      },
      {
        question: 'Why did my Tims Rewards number of banked visits and/or rewards increase?',
        answer: 'If your banked Rewards have gone up, it is because we found additional Rewards on another app account under your email address, and transferred it to your active account.',
      },
      {
        question: 'What if my question isn’t answered with the questions above?',
        answer: 'Please contact <a href="https://global.knowledgeforce.com/casemanagement/form/timhortons/" target="_blank">Guest Services</a> so we can help.',
      },
    ],
  },
];
