/* eslint-disable max-len */
export default {
  logo: './images/fr/timhortons-logo-beta-@4x-FR.png',
  header: 'Préférences de communication',
  unsubscribeHeader: 'Désabonner de courriels',
  confirmationQuestion: 'Êtes sur vous voulez désabonner des',
  unsubscribeButton: 'Oui, désabonner',
  toSubscriptionManagementButton: 'Organise les préférences de communication',
  confirmationHeader: 'Vous avez désabonné',
  confirmation: 'Vous allez plus recevoir les courriels de (catégorie en français)',
  updateSubscriptionsButton: 'Mettre à jour l’abonnement',
  successNotification: 'Vos préférences d’abonnement ont été mise à jour.',
  failureNotification: 'Oops, il y avait un problème. S’il vous pait une autre fois soumettre vous préférences',
  unsubscribeAll: 'Désabonner tous',
};
