import makeHTTPRequest from '../../helpers/makeHTTPRequest';

export default [
  {
    beforeMount() {
      if (this.isOneOfTimmyMeUserScreens()) {
        const userTimmyMeCards = this.getLoggedInUserTimmyMeCards();
        if (!userTimmyMeCards) {
          window.location = '/login.html';
        }
      }
    },
  },
  {
    beforeMount() {
      if ('localStorage' in window) {
        const locale = localStorage.getItem('TimHortonsPromoSiteLocale');
        if (locale) {
          this.$i18n.locale = locale;
        } else {
          const language = navigator.language ||
            navigator.userLanguage ||
            navigator.browserLanguage ||
            navigator.systemLanguage;

          if (language) {
            this.setLocale(language.split(/[-_\s]/)[0].toLowerCase());
          }
        }
      }

      this.locale = this.$i18n.locale;
      this.updateFAQHeaders();
    },
  },
  {
    beforeMount() {
      const { location: { search: queryParams } } = window;
      if (queryParams !== '') {
        const params = queryParams.substr(1).split('&').reduce((acc, param) => {
          const [key, prop] = param.split('=');
          acc[key] = decodeURIComponent(prop);
          return acc;
        }, {});

        if (params.locale) {
          this.setLocale(params.locale.split(/[-_ ]/)[0].toLowerCase());
        }

        if (params.cardId) {
          [this.timmyMeCard] = this.userTimmyMeCards.filter(card => (
            card.id === params.cardId
          ));
        }

        if (params.code && location.pathname === '/groupon.html') {
          const param = params.code.replace(/"/g, '&quot;');
          // eslint-disable-next-line max-len
          this.grouponButtonLink = `https://llkk.adj.st/groupon/${param}?adjust_t=svgrvu&adjust_deeplink=timhortons://groupon/${param}&adjust_fallback=https://www.timhortonsapp.com&linkout=true`;
        }

        Object.assign(this.feedback, params);
        return;
      }

      if (window.location.pathname === '/groupon.html') {
        window.location = '/';
      }
    },
    mounted() {
      if (this.timmyMeCard) {
        this.generateTimmyMeCardBarCode();
      }
    },
  },
  {
    beforeMount() {
      const userHashedId = location.hash.replace('#', '');

      let category;
      if (window.location.search.indexOf('cat') !== -1) {
        const [categoryParam] = window.location.search.split('&');
        [, category] = categoryParam.split('=');

        if (category) {
          this.categoryType = category;
          this.categoryToUnsubscribeFrom = category;
          this.submitButtonDisabled = false;
        }
      }

      if (userHashedId && location.href.indexOf('subscri') !== -1) {
        this.userHashedId = userHashedId;
        this.manageSubscriptionsURL = `/subscriptions.html#${this.userHashedId}`;
        const resourceURL = `${this.endpoints.subscription}/subscriptions/${userHashedId}`;
        const headers = {
          Accept: 'application/vnd.rbi.subscription.v1+json',
          'Content-Type': 'application/json',
          Locale: `${this.$i18n.locale}_CA`,
        };

        makeHTTPRequest('GET', resourceURL, null, headers)
          .then((response) => {
            const subscriptions = response.response;
            this.lastSubscriptionPreference = subscriptions.map(s => s.subscribed);
            this.subscriptions = subscriptions;

            if (category) {
              // eslint-disable-next-line max-len
              const [{ name: categoryToUnsubscribeFrom, mandatory }] = this.subscriptions.filter(
                ({ type }) => (type === category),
              );
              this.categoryToUnsubscribeFrom = categoryToUnsubscribeFrom;

              subscriptions.forEach((subscription) => {
                if (!subscription.mandatory && subscription.type === category) {
                  subscription.subscribed = false;
                  this.submitButtonDisabled = false;
                }
              });

              if (mandatory) {
                // eslint-disable-next-line max-len
                window.location = `/subscriptions.html?cat=${category}&locale=${this.locale}_ca#${this.userHashedId}`;
              }
            }
          })
          .catch(() => {
            this.responseStatus.isError = true;
            this.responseStatus.show = true;
          });
      }
    },
    mounted() {
      this.updateFontSize();
      if (this.grouponButtonLink && window.innerHeight > 600 && window.innerWidth < 481) {
        const button = document.querySelector('.button-center');
        if (button) {
          button.style.marginTop = `${(window.innerHeight - button.clientHeight) / 2}px`;
        }
      }

      // eslint-disable-next-line max-len
      const timhortonsLogoPosition = document.querySelector('.navbar-brand img') ?
        document.querySelector('.navbar-brand img').getBoundingClientRect() : null;

      if (timhortonsLogoPosition) {
        $('.back-button').css('padding-left', timhortonsLogoPosition.x * 1.2);
      }
      const scrollToElement = document.getElementsByName(window.location.hash.replace('#', ''))[0];
      if (scrollToElement) {
        scrollToElement.scrollIntoView();
      }

      window.onresize = () => {
        // eslint-disable-next-line max-len
        const timhortonsLogoNewPosition = document.querySelector('.navbar-brand img').getBoundingClientRect();
        $('.back-button').css('padding-left', timhortonsLogoNewPosition.x * 1.2);
      };
    },
  },
];
