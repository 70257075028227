import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  enCAHomepageContent,
  enCAFeedbackFormContent,
  enSubscriptionContent,
  enGrouponContent,
  enTimmyMeContent,
  enOffersContent,
  frCAHomepageContent,
  frCAFeedbackFormContent,
  frSubscriptionContent,
  frGrouponContent,
  frTimmyMeContent,
  frOffersContent,
} from '../content';

const en = {
  homepage: enCAHomepageContent,
  feedback: enCAFeedbackFormContent,
  subscription: enSubscriptionContent,
  groupon: enGrouponContent,
  timmyme: enTimmyMeContent,
  offers: enOffersContent,
};

const fr = {
  homepage: frCAHomepageContent,
  feedback: frCAFeedbackFormContent,
  subscription: frSubscriptionContent,
  groupon: frGrouponContent,
  timmyme: frTimmyMeContent,
  offers: frOffersContent,
};

Vue.use(VueI18n);
export default new VueI18n({
  locale: 'en', // set locale
  messages: { en, fr },
});
