import i18n from '../configs/i18n';
import data from './app/data';
import mixins from './app/mixins';
import computed from './app/computed';
import methods from './app/methods';
import spaceCardNumbers from '../filters/spaceCardNumbers';
import convertCentsIntoDollars from '../filters/convertCentsIntoDollars';

export default {
  el: '#timhortons-app',
  i18n,
  mixins,
  filters: {
    spaceCardNumbers,
    convertCentsIntoDollars,
  },
  data,
  computed,
  methods,
  beforeMount() {
    if (window.location.href.indexOf('feedback.html') > 0) {
      this.redirectToChatbot();
    }
  },
};
