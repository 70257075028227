/* eslint-disable max-len */
export default {
  header: 'Retour d\'information',
  issue: 'Problème',
  issueTypes: {
    'Issue_Expérience_dans_l’appli': 'Expérience dans l’appli',
    Issue_Expérience_en_restaurant: 'Expérience en restaurant',
    Issue_Commandes: 'Commandes',
    Issue_Paiements_et_compte: 'Paiements et compte',
    Issue_Promotions: 'Promotions',
    Issue_Suggestions: 'Suggestions',
  },
  message: 'Message',
  mobilePlatform: 'Plate-forme mobile',
  appVersion: 'Version de l\'application (affichée dans les paramètres)',
  fullnames: 'Nom complet',
  email: 'Email',
  country: 'Pays',
  selectCountry: 'S\'il vous plaît sélectionnez votre pays',
  postalCode: 'Code postal',
  submit: 'Envoyer des commentaires',
  validation: {
    message: 'Champs obligatoires',
    fullnames: 'Champs obligatoires',
    email: 'Champs obligatoires',
  },
  faqHeader: 'Questions fréquemment posées',
  faqDisclaimer: {
    apple: 'Apple, le logo d’Apple, Apple Pay, iPhone et Touch ID sont des marques de commerce d’Apple Inc., déposées aux États-Unis et dans d’autres pays.',
    android: 'Android, Android Pay et le logo d’Android sont des marques de commerce de Google Inc.',
    timhortons: 'Tim Hortons® et Carte Tim® sont des marques de commerce de Tim Hortons. MC /MD /© 2019 Tim Hortons. Tous droits réservés.',
    lastUpdated: 'Mise à jour : 25 octobre 2019',
  },
};
