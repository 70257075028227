/* eslint-disable max-len */
export default {
  header: {
    slogan: 'Everything you love about Tim Hortons<sup>®</sup>, now \'byte\'-sized',
    loyaltyTitle: 'Tims™ Rewards',
    loyaltyUrl: 'https://timhortons.com/rewards',
    feedbackTitle: 'Feedback',
  },
  features: {
    easier: {
      header: 'Easier',
      message: 'Order and pay',
    },
    faster: {
      header: 'Quicker',
      message: 'Grab and go',
    },
    cheaper: {
      header: 'More personal',
      message: 'Quick reordering',
    },
  },
  terms: 'Terms of Use',
  privacy: 'Privacy Policy',
  images: {
    playstore: {
      url: './images/en/tims-micro-androidstore-375-@2x.png',
      alt: 'Get it on Google Play',
    },
    appstore: {
      url: './images/en/tims-micro-applestore-375-@2x.png',
      alt: 'Download it on the App Store',
    },
    phones: {
      url: './images/timhortonsapp-com-phones-en.png',
      alt: 'Available on iOS and Android',
    },
  },
};
