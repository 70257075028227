$(document).ready(() => {
  const verifyOnMobileDiv = $('.verify-on-mobile');
  if (verifyOnMobileDiv) {
    verifyOnMobileDiv.css('height', () => {
      const height = window.innerHeight - parseFloat($('footer').css('height'));
      $('nav.navbar.navbar-default').hide();
      return height;
    });
  }
});
