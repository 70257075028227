import countries from '../../configs/countries';
import { enCAFAQContent, frCAFAQContent } from '../../content';

let endpoints;
if (process.env.NODE_ENV === 'production') {
  endpoints = {
    feedbackSubmission: 'https://api.rbi.digital/user/v0/feedback',
    subscription: 'https://api.rbi.digital/engagement/v0',
    arh: 'https://api.rbi.digital/payment/v0/arh',
    chatbot: 'https://timchat.timhortons.com/feedback/',
  };
} else {
  endpoints = {
    feedbackSubmission: 'https://dev.rbi.digital/user/v0/feedback',
    subscription: 'https://dev.rbi.digital/engagement/v0',
    arh: 'https://dev.rbi.digital/payment/v0/arh',
    chatbot: 'https://timchatstage.timhortons.com/feedback/',
  };
}

export default function data() {
  let i18n = this.$i18n.getLocaleMessage('en');
  const locale = localStorage.getItem('TimHortonsPromoSiteLocale');
  if (locale) {
    i18n = this.$i18n.getLocaleMessage(locale);
  }

  const issueTypeKeys = Object.keys(i18n.feedback.issueTypes);
  const [defaultIssueType] = issueTypeKeys;
  const issueOptions = issueTypeKeys.map(issueTypeKey => ({
    value: issueTypeKey,
    issueType: i18n.feedback.issueTypes[issueTypeKey],
  }));

  return {
    locale: 'en',
    contentPerLocale: {
      en: enCAFAQContent,
      fr: frCAFAQContent,
    },
    feedback: {
      message: '',
      mobilePlatform: 'iOS',
      appVersion: '',
      fullname: '',
      email: '',
      issueType: defaultIssueType,
      issueOptions,
    },
    timmyMeCredentials: {
      username: '',
      password: '',
    },
    validation: {
      username: {
        regexp: /[\w\s]+/gim,
        invalid: false,
      },
      password: {
        regexp: /[\w\s]+/gim,
        invalid: false,
      },
      message: {
        regexp: /[\w\s]+/im,
        invalid: false,
      },
      appVersion: {
        regexp: /^[\d]+(\.[\d]+)?/,
        invalid: false,
      },
      fullnames: {
        regexp: /[\w\d\W\S\s]+/im,
        invalid: false,
      },
      email: {
        regexp: /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/im,
        invalid: false,
      },
    },
    formInputsAllValid: true,
    submitButtonDisabled: true,
    mobilePlatforms: [],
    userHashedId: 0,
    subscriptions: [],
    lastSubscriptionPreference: [],
    manageSubscriptionsURL: '',
    categoryToUnsubscribeFrom: 'notProvided',
    responseStatus: {
      error: false,
      show: false,
    },
    countries,
    endpoints,
    grouponButtonLink: '/#',
    timmyMeCard: {},
  };
}
