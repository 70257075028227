/* eslint-disable max-len */
export default [
  {
    id: 'about-app',
    header: 'À propos de l’application',
    content: [
      {
        question: 'Qu’est-ce que l’application Tim Hortons<sup>®</sup>?',
        answer: 'L’application Tim Hortons<sup>®</sup> facilite la passation et le paiement des commandes. Sélectionnez et personnalisez vos boissons et produits alimentaires favoris, choisissez votre emplacement Tim Hortons<sup>®</sup> participant préféré et payez — le tout directement dans l’application! Si vous êtes déjà à un restaurant Tim Hortons<sup>®</sup> participant, vous n’avez qu’à faire numériser le code à barres sur votre téléphone pour payer. C’est tout ce que vous aimez à propos de Tim Hortons<sup>®</sup> — désormais plus facile, plus rapide et plus personnalisé.',
      },
      {
        question: 'Quels sont les avantages de l’application Tim Hortons<sup>®</sup>?',
        answer: 'Grâce à l’application Tim Hortons<sup>®</sup>, il est facile de commander. Vous pouvez économiser du temps en commandant de votre téléphone, quand et d’où vous le voulez, numériser le code à barres sur votre téléphone pour payer dans les restaurants participantes, et plus encore! Nous faisons constamment la mise à jour de l’application pour y ajouter de nouvelles fonctions — Restez aux aguets!',
      },
      {
        question: 'Comment le programme de FidéliTim🅪 fonctionne-t-il?',
        answer: 'Il est facile d’obtenir et d’utiliser des récompenses grâce à l’appli Tim Hortons‌<sup>®</sup>. Pour en savoir plus, visitez le <a href="https://timhortons.ca/fidelitim">timhortons.ca/fidelitim</a>.',
      },
    ],
  },
  {
    id: 'download-app',
    header: 'Télécharger l’application',
    content: [
      {
        question: 'Comment puis-je télécharger l’application Tim Hortons<sup>®</sup>?',
        answer: 'Directives en fonction du type d’appareil:',
        list: [
          'iOS: Rendez-vous à la boutique App Store sur votre appareil mobile. Recherchez et sélectionnez « Tim Hortons ». Ensuite, tapez sur « Get » (Obtenir) et « Install » (Installer). Puis, entrez le mot de passe de votre appareil (ou « Touch ID » si cette fonction est activée sur votre appareil).',
          'Android™: Rendez-vous à la boutique Google Play Store sur votre appareil mobile. Recherchez et sélectionnez « Tim Hortons ». Ensuite, tapez sur « Install » (Installer).',
        ],
        numbered: false,
      },
    ],
  },
  {
    id: 'setup-account-and-log-in',
    header: 'Établir un compte',
    content: [
      {
        question: 'Comment puis-je établir un compte pour l’application Tim Hortons<sup>®</sup>?',
        answer: 'Vous pouvez créer un compte rapidement et facilement. Suivez ces étapes simples lorsque vous téléchargez l’application pour la première fois ou après avoir sélectionné des produits au menu pour votre première commande:',
        list: [
          'Pour « Ouvrir une session dans votre compte », choisissez de la faire par l’entremise de Facebook, Google ou votre adresse électronique; puis, suivez les étapes affichées dans l’application.',
          'Sélectionnez votre mode de paiement (carte de crédit/débit, ou compte Apple Pay) afin d’approvisionner un montant dans votre compte numérique Tim Hortons<sup>®</sup> dans l’application. C’est ce que vous utiliserez pour payer toutes vos commandes. Lorsque les fonds dans votre compte sont insuffisants pour régler une commande, votre solde sera réapprovisionné dans l’application suite à votre. confirmation. L’application téléchargera automatiquement la somme minimale de 10 $ ($ CA pour les invités au Canada et $ US pour les invités aux États-Unis) à partir de votre carte de crédit/débit ou votre compte Apple Pay. Veuillez lire les explications ci-dessous sur la façon de relier ces modes de paiement.',
          'Vous pouvez maintenant commencer à commander!',
        ],
      },
      {
        numbered: false,
        question: 'Où mon numéro de compte est-il listé dans l’application Tim Hortons<sup>®</sup>?',
        answer: 'Tapez la case rouge « Numériser pour payer » au bas de l’écran du menu avant de sélectionner des produits au menu. Votre numéro de compte de 16 chiffres est indiqué sous le code à barres.',
      },
    ],
  },
  {
    id: 'transfer-timmy-me',
    header: 'Transférer un solde de ma Carte Tim<sup>®</sup>',
    content: [
      {
        question: 'Pourrais-je encore accéder au <a href="https://timcard.prepaidaccess.com/consumer?locale=fr_CA">site Internet de la Carte Tim<sup>MD</sup></a>?',
        answer: 'Oui. Vous pourrez encore gérer le solde de votre Carte Tim<sup>MD</sup> et la réapprovisionner automatiquement par le biais du <a href="https://timcard.prepaidaccess.com/consumer?locale=fr_CA">site Internet de la Carte Tim<sup>MD</sup></a>, qui n’est pas touché par ces changements.',
      },
      {
        question: 'Combien de cartes-cadeaux Carte Tim<sup>MD</sup> puis-je transférer à mon compte dans l’appli mobile Tim Hortons<sup>®</sup>?',
        answer: 'En ce moment, notre appli plus récente ne supporte qu’une seule Carte Tim<sup>MD</sup> par compte. Vous pouvez transférer le solde de jusqu’à cinq Cartes Tim<sup>MD</sup>.',
      },
      {
        question: 'Puis-je programme la fonction de réapprovisionnement automatisé dans mon compte de l’appli mobile Tim Hortons<sup>®</sup> plus récente?',
        answer: 'Pour le moment, notre appli plus récente ne permet pas aux utilisateurs de réapprovisionner automatiquement le solde de leur compte. Toutefois, si vous voulez passer une commande à l’aide de la nouvelle appli et que le total de votre commande est supérieur au solde dans votre compte, l’appli vous invitera à réapprovisionner votre compte pour pouvoir terminer la transaction.',
      },
      {
        question: 'Comment puis-je annuler la fonction de réapprovisionnement automatisé?',
        answer: 'Pour désactiver la fonction de réapprovisionnement automatisé pour vos cartes, suivez ces étapes :',
        list: [
          'Ouvrez une session sur le <a href="https://timcard.prepaidaccess.com/consumer?locale=fr_CA">site Internet de la Carte Tim<sup>MD</sup></a>.',
          'Sous l’option « Cartes Tim<sup>MD</sup> de Tim Hortons », sélectionnez l’icône « Mes Cartes Tim<sup>MD</sup> » pour revoir vos cartes-cadeaux Carte Tim<sup>MD</sup> enregistrées.',
          'Pour ajuster les paramètres de la fonction de réapprovisionnement automatisé pour chacune de ces cartes-cadeaux Carte Tim<sup>MD</sup>, sélectionnez l’icône du réapprovisionnement automatisé sous la Carte Tim<sup>MD</sup>; puis, sélectionnez « Suspendre » pour désactiver la fonction de réapprovisionnement automatisé',
        ],
        secondaryAnswer: '<b>Remarque :</b> Veuillez désactiver la fonction au moins 24 heures avant le prochain réapprovisionnement prévu à l’horaire. Lorsque le montant du réapprovisionnement automatisé a été versé au compte, la transaction ne peut pas être inversée.',
      },
      {
        question: 'Y a-t-il des limites aux montants du solde ou d’un achat dans l’appli mobile Tim Hortons<sup>®</sup> plus récente?',
        answer: 'Oui. L’appli mobile Tim Hortons<sup>®</sup> plus récente exige que vous approvisionniez votre solde d’un montant minimal de 5,00 $ lorsque vous passez votre première commande. La valeur totale maximale de votre compte est sujette aux modalités de la Carte Tim<sup>MD</sup> enregistrée dans l’appli mobile Tim Hortons<sup>®</sup> plus récente; toutefois, le solde de la majorité des Cartes Tim<sup>MD</sup> ne peut pas dépasser la somme de 100,00 $. À titre de mesure de sécurité additionnelle afin de minimiser les activités frauduleuses, vous ne pouvez pas réapprovisionner le solde de votre compte dans l’appli mobile Tim Hortons<sup>®</sup> plus récente d’une somme de plus de 50,00 $ par semaine à l’aide de votre carte de crédit. Tout réapprovisionnement d’un compte qui amènerait le solde de votre compte au-delà de la limite de 100,00 $ ou tout réapprovisionnement à l’aide d’une carte de crédit qui dépasserait la limite hebdomadaire de 50,00 $ ne sera pas traité.<br><br>Par exemple, si votre solde est de 80,00 $ et que vous essayez de réapprovisionner votre compte d’un montant de 50,00 $ à l’aide d’une carte de crédit, la transaction ne sera pas traitée parce qu’elle amènerait le solde de votre compte au-delà de la limite de 100,00 $. Toutefois, vous pourriez réapprovisionner votre compte de la somme de 20,00 $ à l’aide d’une carte de crédit pour amener le solde de votre compte au montant maximal de 100,00 $.',
      },
      {
        question: 'Que dois-je faire si j’ai d’autres questions?',
        answer: 'N’hésitez surtout pas à communiquer avec l’équipe de l’application mobile en tout temps en remplissant ce <a href="https://timhortonsapp.com/feedback.html?locale=fr_ca">formulaire de commentaires</a>. Il nous fera plaisir de répondre à toutes vos questions.',
      },
    ],
  },
  {
    id: 'find-a-tims-location',
    header: 'Localiser un emplacement Tim Hortons<sup>®</sup> activé pour les commandes et paiements mobiles',
    content: [
      {
        question: 'Où puis-je utiliser l’application Tim Hortons<sup>®</sup> pour les commandes et paiements mobiles?',
        answer: 'Vous pouvez utiliser l’application Tim Hortons<sup>®</sup> dans les établissements Tim Hortons<sup>®</sup> participants. Vous pouvez connaître les emplacements participants en tapant sur « Commandez maintenant » dans la page « Accueil » et en agrandissant ou rapetissant la carte pour localiser l’établissement que vous recherchez. Tapez la case à cocher sur le restaurant duquel vous aimeriez commander.',
      },
      {
        question: 'Est-ce tous les restaurants Tim Hortons<sup>®</sup> qui acceptent les commandes et paiements mobiles?',
        answer: 'Non. Pour le moment, ce ne sont pas tous les restaurants Tim Hortons<sup>®</sup> qui acceptent les commandes et paiements mobiles; toutefois, vous pouvez connaître les restaurants participants à proximité en suivant les directives ci-dessus.',
      },
      {
        question: 'Comment puis-je trouver le restaurant Tim Hortons<sup>®</sup> le plus près?',
        answer: 'Assurez-vous d’activer les paramètres de localisation sur votre appareil mobile. Avant de commencer une commande, l’application affichera les restaurants situés à proximité de vous qui acceptent les commandes et paiements mobiles sur la page « Sélectionner le restaurant ». Si vous voulez utiliser l’application sans activer les paramètres de localisation, vous pouvez sélectionner un établissement sur la carte en trouvant manuellement où vous vous situez sur la carte et en sélectionnant le restaurant le plus près du lieu où vous vous trouvez.',
      },
      {
        question: 'Que dois-je faire si je ne vois pas le restaurant duquel je veux commander?',
        answer: 'Agrandissez la carte pour afficher un plus grand nombre de restaurants. Tapez sur le crochet de l’emplacement duquel vous voulez commander. Si vous ne voyez pas de restaurant participant à proximité, essayez de fermer l’application et de l’ouvrir à nouveau pour réinitialiser l’affichage des restaurants activés.',
      },
    ],
  },
  {
    id: 'place-an-order',
    header: 'Passer une commande',
    content: [
      {
        question: 'Comment puis-je sélectionner des produits du menu?',
        answer: 'Tapez sur le produit dans le menu. Apportez toute modification que vous voulez à votre produit à l’écran suivant. Ensuite, tapez sur le bouton « Ajouter à la commande » au bas de l’écran. Vous reviendrez alors à l’écran du menu. Pour ajouter d’autres produits ou commander un nombre multiple du même produit, répétez les étapes précédentes.',
      },
      {
        question: 'Comment puis-je modifier une commande ou supprimer des produits dans mon chariot avant de passer à la caisse?',
        answer: 'Directives en fonction du type d’appareil:',
        list: [
          'iOS: Dans la page « Ma commande », glissez vers la gauche le produit dans la commande; puis, tapez le bouton rouge pour « Retirer » ou le bouton gris pour « Modifier » le produit. Tapez sur « Ajouter un produit » dans le coin supérieur droit de l’écran pour revenir au menu et sélectionner d’autres produits.',
          'Android: Sur la page « Ma commande » ou « Passer à la caisse », tapez sur les trois petits points à côté d’un produit de la commande. Puis, tapez sur « Retirer » ou « Modifier », ou encore tapez sur « + » pour revenir au menu afin d’ajouter d’autres produits.',
        ],
        numbered: false,
      },
      {
        question: 'Comment puis-je passer une commande mobile?',
        answer: 'Après avoir fait vos choix au menu, tapez sur le bouton rouge « Voir la commande » au bas de l’écran. Ensuite, tapez sur « Continuer » sur la page « Ma commande ». S’il s’agit de votre première commande et que vous n’avez pas établi de compte Apple Pay, ou que vous n’avez pas encore programmé un mode de paiement, l’application vous demandera de le faire. Puis, vous taperez sur « Passer la commande » ou « Charger et passer la commande » pour terminer votre transaction!',
      },
      {
        question: 'Que dois-je faire si j’ai de la difficulté à utiliser mon application Tim Hortons<sup>®</sup>?',
        answer: 'Si votre application Tim Hortons<sup>®</sup> ne fonctionne pas bien, vous utilisez peut-être une version désuète qui n’est plus supportée par le système. Veuillez télécharger la version la plus récente de l’application en allant à votre boutique d’applications et en acceptant la plus récente mise à jour de l’application. Si le problème persiste, veuillez <a href="./feedback.html">communiquer avec nous</a>.',
      },
      {
        question: 'Que dois-je faire lorsque je reçois un message d’erreur ou d’échec de commande?',
        answer: 'Fermez l’application pour supprimer votre commande, puis essayez à nouveau. Vous ne serez pas facturé pour les commandes échouées. Si le problème persiste, veuillez <a href="./feedback.html">communiquer avec nous</a> en fournissant les renseignements suivants et nous chercherons à déterminer la cause du problème:',
        list: [
          'Adresse électronique associée à votre compte dans l’application Tim Hortons<sup>®</sup>',
          'Adresse du restaurant Tim Hortons<sup>®</sup> où vous avez passé votre commande tentative',
          'Date et heure auxquelles la commande tentative a été passée',
          'Mode de service sélectionné : Service au volant, À emporter, En restaurant',
        ],
        numbered: false,
      },
    ],
  },
  {
    id: 'add-to-my-order',
    header: 'Ajouter à ma commande',
    content: [
      {
        question: 'Puis-je ajouter d’autres produits à ma commande mobile lorsque j’arrive au comptoir ou au service au volant?',
        answer: 'Si vous voulez ajouter des produits à votre commande et payer par l’entremise de l’application, vous pouvez passer une autre commande mobile séparée une fois rendu au restaurant après avoir tapé sur « Je suis ici » pour votre première commande. Si vous voulez payer en personne, vous pouvez passer séparément une autre commande auprès de l’employé au comptoir du restaurant ou au service au volant. Vous pourrez alors payer en utilisant la fonction Numériser-pour-payer, une carte de crédit ou débit, une Carte Tim<sup>®</sup> de plastique ou de l’argent comptant.',
      },
    ],
  },
  {
    id: 'pay-for-order',
    header: 'Payer ma commande',
    content: [
      {
        question: 'Comment puis-je relier ma carte de crédit/débit à mon compte dans l’application mobile de Tim Hortons<sup>®</sup> pour approvisionner ou réapprovisionner mon solde?',
        answer: 'L’application vous demandera d’ajouter un mode de paiement lorsque vous passerez votre toute première commande dans l’application. Après avoir sélectionné les produits du menu Tim Hortons<sup>®</sup> que vous voulez commander, tapez sur « Voir la commande », « Continuer » et « Confirmer l’emplacement » et sélectionnez la façon dont vous passerez chercher votre commande. Ensuite, sélectionnez votre mode de paiement pour approvisionner le solde de votre compte dans l’application Tim Hortons<sup>®</sup>. Les modes de paiement incluent un compte Apple Pay ou une carte de crédit/débit. Si vous sélectionnez le paiement par carte de crédit/débit, entrez le numéro de votre carte de crédit/débit qui sera sauvegardé dans l’application aux fins d’usage futur et utilisé automatiquement pour réapprovisionner votre solde dans l’application Tim Hortons<sup>®</sup>.',
      },
      {
        question: 'Comment la fonction de réapprovisionnement automatique fonctionne-t-elle?',
        answer: 'Après avoir programmé le solde initial de votre compte numérique Tim Hortons<sup>®</sup> dans l’application Tim Hortons<sup>®</sup>, votre mode de paiement sera sauvegardé pour usages futurs. Lorsque le solde de votre compte de l’application Tim Hortons<sup>®</sup> est inférieur au montant requis pour régler votre commande, l’application vous demandera de réapprovisionner le solde de votre compte en fonction du montant de réapprovisionnement sélectionné.',
      },
      {
        question: 'Comment puis-je modifier le montant de réapprovisionnement qui m’est facturé?',
        answer: 'Le montant de réapprovisionnement dans l’application Tim Hortons<sup>®</sup> est programmé par défaut à la somme de 10 $. Ce montant peut toutefois être augmenté par tranches de 10 $, 25 $, 50 $ ou 100 $. Si vous voulez modifier le montant de réapprovisionnement par défaut, dans votre application Tim Hortons<sup>®</sup>, tapez sur l’image des engrenages dans le coin supérieur gauche de l’écran pour ouvrir les « Paramètres ». Puis, tapez sur « Mon solde » et sélectionnez un montant de réapprovisionnement du solde. Sur iOS, vous devez taper sur « Sauvegarder » dans le coin supérieur droit pour programmer votre nouveau montant de réapprovisionnement par défaut.',
      },
      {
        question: 'Quelle est la fonction Numériser-pour-payer et comment puis-je l’utiliser?',
        answer: 'La fonction Numériser-pour-payer est un moyen facile et pratique de payer pour votre commande au comptoir ou au guichet du service au volant des restaurants Tim Hortons<sup>®</sup>. Après avoir ouvert une session dans l’application Tim Hortons<sup>®</sup>, vous pouvez accéder au bouton de la fonction « Numériser-pour-payer » depuis la page d’accueil. Lorsque vous tapez sur le bouton « Numériser-pour-payer », vous affichez le code à barres sur votre téléphone. Vous pouvez alors le faire numériser à la caisse pour payer pour votre commande. Le montant total de votre commande sera déduit du solde dans votre compte de l’application Tim Hortons<sup>®</sup>. Si votre solde est insuffisant pour régler la commande, l’application réapprovisionnera automatiquement votre compte du montant présélectionné (minimum de 10 $) ou vous pouvez taper sur l’image des engrenages dans le coin supérieur gauche de l’application pour accéder aux « Paramètres »; puis, vous tapez sur le crochet vert à côté d’un montant de réapprovisionnement de solde pour ensuite taper sur « Sauvegarder » pour ajouter ce montant à votre solde dans l’application Tim Hortons<sup>®</sup>.',
      },
      {
        question: 'Comment puis-je programmer Apple Pay ou Android Pay dans mon compte de l’application mobile Tim Hortons<sup>®</sup>?',
        answer: 'Directives en fonction du type d’appareil:',
        list: [
          'Apple Pay: Si la fonction de paiement Apple Pay est déjà programmée dans votre appareil, elle sera automatiquement désignée en tant que votre mode de paiement préféré. Par contre, vous pourrez ajouter une carte de crédit/débit différente en tapant sur l’icône des engrenages dans le coin supérieur gauche de votre écran pour accéder aux « Paramètres »; puis, à « Mode de paiement ». Si la fonction de paiement Apple Pay n’est pas programmée dans votre appareil, vous pouvez l’ajouter en accédant à l’application du portefeuille. Ensuite, vous réinitialisez votre application Tim Hortons<sup>®</sup> et la fonction de paiement Apple Pay sera activée. Vous pouvez aller à « Paramètres », puis « Mode de paiement » pour afficher ou modifier votre mode de paiement par défaut.',
          'Android Pay: En ce moment, Android Pay n’est pas supporté.',
        ],
        numbered: false,
      },
      {
        question: 'Que puis-je faire si je ne vois pas mon solde antérieur dans l’application?',
        answer: 'Assurez-vous d’avoir ouvert une session dans l’application à l’aide de la bonne identité de connexion. Par exemple, si vous avez ouvert une session avec Facebook la première fois que vous avez utilisé l’application, continuez d’ouvrir vos sessions avec Facebook chaque fois que vous utilisez l’application. Pour affiché votre solde après avoir ouvert une session, tapez sur l’image des engrenages dans le coin supérieur gauche de l’application pour accéder aux « Paramètres », puis sur « Mon solde ».',
      },
      {
        question: 'Puis-je passer une commande mobile et payer ensuite en argent comptant?',
        answer: 'Non. Toutes les commandes mobiles doivent être réglées en réapprovisionnant le solde de votre compte Tim Hortons<sup>®</sup> dans l’application.',
      },
      {
        question: 'Puis-je utiliser des bons d’achat pour payer mes commandes mobiles?',
        answer: 'L’application Tim Hortons<sup>®</sup> offrira des promotions spéciales pour les commandes mobiles!',
      },
      {
        question: 'Comment puis-je demander le remboursement d’une commande mobile?',
        answer: 'Vous pouvez recevoir un remboursement pour une commande mobile dans l’une ou l’autre des options suivantes:',
        list: [
          'En restaurant: Apportez votre appareil mobile à un gérant de restaurant Tim Hortons<sup>®</sup>. Montrez-lui votre commande mobile en tapant sur l’image des engrenages pour accéder aux paramètres dans le coin supérieur droit de l’écran. Ensuite, tapez sur « Historique des commandes » pour afficher vos commandes par date, par restaurant et par montant en dollars. Après vérification, le cas échéant, le gérant ou la gérante sera en mesure de délivrer un remboursement dans votre solde de l’application Tim Hortons<sup>®</sup> à l’aide de la fonction Numériser-pour-payer dans l’application.',
          '<a href="./feedback.html">Communiquer avec nous</a>',
        ],
      },
      {
        question: 'Comment puis-je demander un remboursement d’un montant réapprovisionné dans l’application Tim Hortons<sup>®</sup>?',
        answer: 'Si vous êtes d’avis que vous avez été facturé par erreur pour un réapprovisionnement de solde de compte, veuillez <a href="./feedback.html">communiquer avec nous</a> en fournissant les renseignements ci-dessous et, si possible, nous enquêterons la situation:',
        list: [
          'Problème spécifique encouru (où vous avez été coincé, message d’erreur, etc.)',
          'Adresse du restaurant Tim Hortons<sup>®</sup> auprès duquel vous avez tenté de commander',
          'Adresse électronique associée à votre compte dans l’application Tim Hortons<sup>®</sup>',
          'Mode de paiement et nom sur la carte de crédit/débit',
          'Montant du réapprovisionnement',
        ],
      },
      {
        question: 'Que dois-je faire si le prix dans l’application est différent du prix listé dans le restaurant Tim Hortons<sup>®</sup> où ma commande a été passée?',
        answer: 'Veuillez communiquer avec nous et inclure l’adresse du restaurant Tim Hortons<sup>®</sup>, le(s) produit(s) que vous avez acheté(s), le prix dans l’application versus le prix en restaurant, et il nous fera plaisir d’examiner la situation. Les prix pour les commandes passées au Canada sont affichés en dollars canadiens. Les prix pour les commandes passées aux États-Unis sont affichés en dollars américains.',
      },
    ],
  },
  {
    id: 'pickup-my-order',
    header: 'Collecter ma commande',
    content: [
      {
        question: 'Où dois-je passer prendre ma commande mobile?',
        answer: 'Pour les commandes « En restaurant » et « À emporter », localisez l’affiche « Collecte mobile » au-dessus du comptoir dans le restaurant. L’emplacement de l’aire de collecte des commandes mobiles peut varier d’un restaurant Tim Hortons<sup>®</sup> à l’autre. Pour les commandes « Service au volant », vous vous rendez au haut-parleur du service au volant et dites à l’employé que vous avez passé une commande mobile; puis, vous lui donnez votre nom. Il obtiendra la commande pour vous!',
      },
      {
        question: 'Que se passe-t-il si je passe une commande mobile, mais qu’elle n’a pas été envoyée au restaurant Tim Hortons auquel je me suis rendu?',
        answer: 'Montrez à l’employé dans le restaurant la commande que vous avez passée en tapant sur l’icône des engrenages dans le coin supérieur gauche de l’écran pour ouvrir les Paramètres. Puis, tapez sur « Historique des commandes » pour afficher vos commandes par date, par restaurant et par montant de la commande en dollars. Après vérification, un employé de Tim Hortons<sup>®</sup> préparera la commande pour vous.',
      },
      {
        question: 'Que dois-je faire si j’ai sélectionné « Service au volant » lorsque j’ai passé ma commande; mais, à mon arrivée au restaurant Tim Hortons<sup>®</sup>, je décide de collecter ma commande « En restaurant » ou « À emporter »?',
        answer: 'Entrez au restaurant Tim Hortons<sup>®</sup> et avisez un employé que vous avez passé une commande mobile avec un mode de collecte « Service au volant », mais que vous préférez la prendre « En restaurant » ou « À emporter ». À ce moment-là, un employé préparera votre commande et vous la remettra à la station de collecte des commandes mobiles dans le restaurant.',
      },
    ],
  },
  {
    id: 'view-my-order',
    header: 'Afficher l’historique de mes commandes',
    content: [
      {
        question: 'Comment puis-je afficher l’historique de mes commandes?',
        answer: 'Dans votre application Tim Hortons<sup>®</sup>, tapez sur l’icône des engrenages dans le coin supérieur gauche de l’écran pour accéder aux paramètres. Puis, tapez sur « Historique des commandes » pour afficher vos commandes par date, par restaurant et par montant total de la commande en dollars.',
      },
    ],
  },
  {
    id: 'edit-notifications',
    header: 'Modifier l’accès/l’avis',
    content: [
      {
        question: 'L’application Tim Hortons<sup>®</sup> a-t-elle accès à l’endroit où je me trouve?',
        answer: 'Si vous activez la fonction de localisation ou sélectionnez un restaurant Tim Hortons<sup>®</sup>, l’application utilise votre emplacement uniquement pendant que vous avez activé l’application pour trouver les restaurants participants à proximité, préparer votre commande mobile rapidement pour qu’elle soit prête à votre arrivée et vous envoyer des offres spéciales. Vous pouvez activer cette fonction ou la désactiver en tout temps dans les paramètres de votre application.',
      },
      {
        question: 'Comment puis-je activer et désactiver la fonction de localisation dans l’application Tim Hortons<sup>®</sup>?',
        answer: 'Directives en fonction du type d’appareil:',
        list: [
          'iOS: Allez aux paramètres des applications dans votre appareil iOS. Faites dérouler la page jusqu’à ce que vous voyiez l’icône de l’application Tim Hortons<sup>®</sup>. Puis, tapez sur la fonction de localisation et sélectionnez les paramètres que vous voulez activer ou désactiver.',
          'Android™: Allez aux paramètres et tapez sur la fonction pour accéder à l’emplacement. Puis, activez ou désactivez la fonction à votre guise.',
        ],
        numbered: false,
      },
      {
        question: 'Pourquoi l’application Tim Hortons<sup>®</sup> m’envoie-elle des avis?',
        answer: 'Ces avis pourraient inclure des alertes, des indices sonores et des insignes d’icône pour vous aviser lorsque votre commande est en cours de préparation ou pour vous faire part des plus récentes promotions de Tim Hortons<sup>®</sup>. Vous pouvez programmer ces avis en accédant aux paramètres, puis en tapant sur « Préférences » et en sélectionnant « Communications du compte seulement » dans votre application. Remarque : Vous recevrez encore les renseignements relatifs à votre compte, notamment les réapprovisionnements de carte.',
      },
      {
        question: 'Comment puis-je me désabonner des avis mobiles, messages textes et(ou) alertes par courriel qui ne sont pas reliés à mes commandes mobiles ou au solde de mon compte?',
        answer: 'Directives en fonction du type d’appareil:',
        list: [
          'iOS: Tapez sur les paramètres des applications dans votre appareil iOS. Faites dérouler vers le bas. Tapez sur les avis/notifications, puis tapez sur l’application Tim Hortons<sup>®</sup>. De là, choisissez « On » (Activation) ou « Off » (Désactivation) pour permettre l’envoi d’avis ou de notifications, au choix.',
          'Android™: Tapez sur les paramètres de l’appareil Android. Puis, tapez sur Notifications. Faites dérouler vers le bas et sélectionnez l’application de Tim Hortons<sup>®</sup>. Modifiez les paramètres au choix ou cochez la fonction pour tout bloquer afin de désactiver tous les avis/notifications.',
        ],
        numbered: false,
      },
    ],
  },
  {
    id: 'login-email-only',
    header: 'Ouvrir une session avec l’adresse courriel seulement',
    content: [
      {
        question: 'Pourquoi cessez-vous d’offrir la possibilité d’ouvrir une session avec Facebook ou Google dans l’appli Tim Hortons?',
        answer: 'Afin d’offrir à nos utilisateurs une expérience mieux intégrée et une appli encore plus sécurisée, nous avons regroupé tous les comptes sous la même adresse électronique, de manière à réduire le nombre de points d’entrée et à permettre aux utilisateurs de conserver tous leurs renseignements au même endroit.',
      },
      {
        question: 'Qu’adviendra-t-il du solde de ma Carte Tim<sup>MD</sup>, de mes récompenses FidéliTim<sup>MC</sup>, etc.?',
        answer: 'À compter du début du mois de novembre, si vous aviez plusieurs comptes partageant la même adresse courriel, vous trouverez tous les renseignements de votre compte, y compris les soldes et les récompenses, en ouvrant une session à l’aide de votre adresse courriel.',
      },
      {
        question: "J'ai un compte auquel je me connectais via Facebook/Google et je viens d'ouvrir une session avec mon adresse courriel, mais mes données ne semblent pas avoir été transférées. Pourquoi?",
        answer: "Le transfert des renseignements provenant de votre compte connecté à Facebook/Google (solde de la Carte Tim<sup>MD</sup>, récompenses, etc.) ne sera effectué qu’au début du mois de novembre. D'ici là, toutes les données continueront d'être affichées dans le compte d'origine.",
      },
      {
        question: "Jusqu'à quand est-ce que je peux me connecter via mon compte Facebook ou Google?",
        answer: "Vous pouvez continuer de vous connecter via votre compte Facebook ou Google jusqu’au début de novembre; c’est à ce moment-là que nous commencerons à rassembler tous les comptes en un seul compte accessible via votre adresse courriel. Lorsque la migration sera terminée, vous serez invité à fermer votre session et à en ouvrir une nouvelle à l'aide de l'adresse courriel associée à votre compte Facebook ou Google.",
      },
      {
        question: 'Je me connecte habituellement avec mon adresse courriel. Qu’est-ce que je fais maintenant?',
        answer: 'Continuez d’ouvrir vos sessions avec votre adresse courriel. Vous ne verrez aucun changement.',
      },
      {
        question: 'Je me connecte habituellement avec Google ou Facebook. Qu’est-ce que je fais maintenant?',
        answer: 'À compter du début du mois de novembre, pour ouvrir une session dans l’appli, vous devrez le faire avec votre adresse courriel. L’adresse courriel que vous devriez utiliser est celle qui est associée à votre compte Facebook ou Google. Si vous avez des doutes sur l’adresse courriel à utiliser, vérifiez vos boîtes de réception – nous avons envoyé à cette adresse un message de confirmation. Vous pouvez voir quelle adresse courriel est associée à votre compte dans Paramètres > Profil.',
      },
      {
        question: 'Comment puis-je ouvrir une session avec mon adresse courriel?',
        answer: 'Sur l’appli, appuyez sur « Accès par courriel ». Entrez votre adresse courriel (celle-ci devrait être celle qui est associée à votre compte Facebook ou Google). Vous recevrez ensuite par courriel un code de vérification à 6 chiffres à entrer dans l’appli. C’est tout!',
      },
      {
        question: 'Puis-je changer l’adresse courriel associée à mon compte?',
        answer: 'Non, votre adresse courriel continuera d’être utilisée après cette migration afin de conserver vos renseignements dans un seul compte.',
      },
      {
        question: 'Je ne connais pas le mot de passe de l’adresse courriel qu’on me demande d’utiliser pour obtenir mon code de vérification. Que dois-je faire?',
        answer: 'Si vous ne connaissez pas le mot de passe de votre compte de courriel, veuillez suivre les étapes fournies par votre service de courriel en cas d’oubli du mot de passe ou écrivez à votre service de courriel pour lui demander de réinitialiser votre mot de passe.',
      },
      {
        question: "Je n'ai pas accès au compte courriel que je dois utiliser pour ouvrir une session dans l'appli mobile. Que dois-je faire?",
        answer: 'Veuillez créer un nouveau compte sur l’appli mobile avec une adresse courriel à laquelle vous avez accès. À partir de novembre, contactez les <a href="https://global.knowledgeforce.com/casemanagement/form/timhortons?lang=fr" target="_blank"> Services aux invités </a> pour faire transférer vos récompenses/visites ainsi que votre solde en argent, si vous en avez un, à votre nouveau compte dans l’appli mobile.',
        secondaryAnswer: 'Vous devrez fournir l’ancienne adresse courriel ainsi que la nouvelle pour confirmer que vous êtes bien le propriétaire du compte. Veuillez prendre note que votre historique de commandes et votre carte de plastique FidéliTim<sup>MC</sup> ne seront pas transférés. Seuls les récompenses/visites et l’argent peuvent être transférés.',
      },
      {
        question: 'Pourquoi ma carte de plastique FidéliTim<sup>MC</sup> ne fonctionne-t-elle plus?',
        answer: 'Après le transfert du compte, votre carte de plastique peut avoir été remplacée par une carte numérique FidéliTim<sup>MC</sup>. Veuillez vérifier sur l’appli, dans Paramètres > FidéliTim, si le numéro de carte correspond à celui de votre carte de plastique. Vous pouvez continuer à utiliser la carte numérique FidéliTim<sup>MC</sup> dans l’appli mobile. Vous pouvez aussi demander une nouvelle carte de plastique en restaurant, puis l’enregistrer sur l’appli.',
      },
      {
        question: 'Pourquoi le solde de ma Carte Tim<sup>MD</sup> a-t-il augmenté?',
        answer: 'Si le solde de votre Carte Tim<sup>MD</sup> a augmenté, c’est parce que nous avons trouvé un solde de Carte Tim<sup>MD</sup> additionnel sur un autre compte d’appli mobile ayant la même adresse courriel et nous l’avons transféré à votre compte actif. C’est une bonne nouvelle! Vous avez maintenant plus d’argent sur votre Carte Tim<sup>MD</sup>!',
      },
      {
        question: 'Pourquoi le nombre de visites/récompenses accumulées dans mon compte FidéliTim<sup>MC</sup> a-t-il augmenté?',
        answer: 'Si le nombre de visites/récompenses FidéliTim<sup>MC</sup> a augmenté, c’est parce que nous avons trouvé des récompenses/visites additionnelles sur un autre compte d’appli mobile ayant la même adresse courriel et nous les avons transférées à votre compte actif.',
      },
      {
        question: 'Que dois-je si les questions et réponses ci-dessus ne répondent pas à ma question?',
        answer: 'Veuillez contacter les <a href="https://global.knowledgeforce.com/casemanagement/form/timhortons?lang=fr" target="_blank"> Services aux invités </a>  et nous serons heureux de vous aider.',
      },
    ],
  },
];
