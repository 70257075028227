/* eslint-disable max-len */
export default {
  logo: './images/en/timhortons-logo-beta-@4x-EN.png',
  header: 'Communication Preferences',
  unsubscribeHeader: 'Unsubscribe from emails',
  confirmationQuestion: 'Are you sure you want to unsubscribe from',
  unsubscribeButton: 'Yes, Unsubscribe',
  toSubscriptionManagementButton: 'Manage Communication Preferences',
  confirmationHeader: 'You\'ve Unsubscribed',
  confirmation: 'You will no longer receive',
  updateSubscriptionsButton: 'Update Subscription',
  successNotification: 'Your subscription preferences have been updated.',
  failureNotification: 'Oops! Something went wrong. Please re-submit your preferences.',
  unsubscribeAll: 'Unsubscribe All',
};
