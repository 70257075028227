import $ from 'jquery';
import 'bootstrap';
import Vue from 'vue';

import './helpers/redirect';
import appComponent from './components/app';


$(document).ready(() => {
  window.THPromoSiteApp = new Vue(appComponent);
});
