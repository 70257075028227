export default [
  { name: 'Canada', code: 'CA' },
  { name: 'United States', code: 'US' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Oman', code: 'OM' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
];
