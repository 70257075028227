import bwipjs from 'bwip-js';
import makeHTTPRequest from '../../helpers/makeHTTPRequest';

export default {
  validateForm(credentialToChecked) {
    const formFieldValues = Object.values(this[credentialToChecked]);
    this.formInputsAllValid = formFieldValues.every(prop => prop !== '' && prop.length !== 0);

    const credentialsKeys = Object.keys(this[credentialToChecked]);
    credentialsKeys.forEach((key) => {
      if (this.validation[key]) {
        this.validation[key].invalid = false;
        const valueIsNotValid = this.validation[key].regexp.test(this[credentialToChecked][key]);
        if (!valueIsNotValid) {
          this.validation[key].invalid = true;
        }
      }
    });

    if (this.formInputsAllValid && credentialToChecked === 'feedback') {
      if (!this.validation.email.regexp.test(this.feedback.email)) {
        this.validation.email.invalid = true;
      }
    }
  },
  redirectToChatbot() {
    window.location = this.endpoints.chatbot;
  },
  enableSubmitButton() {
    const currentSubscriptionPreference = this.subscriptions.map(s => s.subscribed);
    const preferenceUpdates = currentSubscriptionPreference.filter(
      (subscriptionChoice, index) => (
        subscriptionChoice !== this.lastSubscriptionPreference[index]
      ),
    );
    this.submitButtonDisabled = preferenceUpdates.length === 0;
  },
  unMarkAllSubscriptions() {
    this.subscriptions = this.subscriptions.map((subscription) => {
      if (subscription.type !== 'account') subscription.subscribed = false;
      return subscription;
    });
    this.submitButtonDisabled = false;
  },
  updateSubscriptionPreferences() {
    const resourceURL = `${this.endpoints.subscription}/subscriptions/${this.userHashedId}`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.rbi.subscription-update.v1+json',
      Locale: `${this.$i18n.locale}_CA`,
    };

    if (this.categoryToUnsubscribeFrom !== 'notProvided') {
      this.subscriptions = this.subscriptions.map((subscription) => {
        if (subscription.name === this.categoryToUnsubscribeFrom) {
          subscription.subscribed = false;
        }
        return subscription;
      });
    }

    const categories = this.subscriptions.reduce((subscriptions, { type, subscribed }) => (
      Object.assign(subscriptions, { [type]: subscribed })
    ), {});

    makeHTTPRequest('PUT', resourceURL, categories, headers)
      .then(() => {
        window.scrollTo(0, 0);
        this.submitButtonDisabled = true;
        if (this.categoryToUnsubscribeFrom === 'notProvided') {
          this.responseStatus.show = true;
          this.responseStatus.isError = false;
        }

        if (this.categoryToUnsubscribeFrom !== 'notProvided') {
          // eslint-disable-next-line max-len
          window.location = `/unsubscribed.html?cat=${this.categoryType}#${this.userHashedId}`;
        }
      })
      .catch(() => {
        window.scrollTo(0, 0);
        this.submitButtonDisabled = false;
        this.responseStatus.show = true;
        this.responseStatus.isError = true;
      });
  },
  setLocale(locale) {
    let newLocale = locale.toLowerCase();

    if (!locale || ['en', 'fr'].indexOf(locale) === -1) {
      newLocale = 'en';
    }

    this.locale = newLocale;
    this.$i18n.locale = this.locale;
    this.updateFontSize();

    if ('localStorage' in window) {
      localStorage.setItem('TimHortonsPromoSiteLocale', this.$i18n.locale);
    }

    this.updateFAQHeaders();
  },
  toggleLocale(oldLocale) {
    let selectedLocale = this.locale.toLowerCase();
    if (oldLocale && ['en', 'fr'].indexOf(oldLocale) !== -1) {
      selectedLocale = oldLocale.toLowerCase();
    }

    this.setLocale(selectedLocale === 'en' ? 'fr' : 'en');
  },
  updateFAQHeaders() {
    this.faqContent = this.contentPerLocale[this.$i18n.locale];
    const faqSummaryContent = this.faqContent.map(content => ({
      id: content.id,
      header: content.header,
    }));

    this.faqSummary = [];
    while (faqSummaryContent.length) {
      this.faqSummary.push(faqSummaryContent.splice(0, 4));
    }

    const PayForYourOrderHeader = this.faqSummary[1].pop();
    this.faqSummary[2].unshift(PayForYourOrderHeader);
  },
  redirectToARH() {
    window.location = `https://timcard.prepaidaccess.com/consumer?locale=${this.$i18n.locale}_CA`;
  },
  submitTimmyMeLoginForm() {
    this.validateForm('timmyMeCredentials');

    if (this.formInputsAllValid) {
      const resourceURL = `${this.endpoints.arh}`;
      const headers = {
        Accept: 'application/vnd.rbi.extended-arh-prepaid-cards.v1+json',
        'Content-Type': 'application/vnd.rbi.arh-login-credentials.v1+json',
      };

      makeHTTPRequest('POST', resourceURL, this.timmyMeCredentials, headers)
        .then(({ response: timmyCardsResponse }) => {
          window.localStorage.setItem('THTimmyMeCards', JSON.stringify(timmyCardsResponse));
          this.responseStatus.error = false;
          this.responseStatus.show = false;
          if (typeof me === 'undefined') {
            window.location = '/usertimmymecards.html';
          }
        })
        .catch(() => {
          this.responseStatus.error = true;
          this.responseStatus.show = true;
          // eslint-disable-next-line no-console
          console.log(this.validation.username.invalid);
        });
      return;
    }

    console.log(this.validation); // eslint-disable-line
  },
  isOneOfTimmyMeUserScreens() {
    const timmyMeAppPages = ['usertimmymecards', 'timmymecard'];
    const isOneOfTimmyMeUserScreens = timmyMeAppPages.some(page => (
      window.location.pathname.indexOf(page) > -1
    ));

    return isOneOfTimmyMeUserScreens;
  },
  getLoggedInUserTimmyMeCards() {
    const userTimmyMeCardsStoredLocally = window.localStorage.getItem('THTimmyMeCards');
    const isNotLoggedInViews = ['login', 'downloadapp'].some(path => (
      location.pathname.indexOf(path) !== -1
    ));

    if (!userTimmyMeCardsStoredLocally || isNotLoggedInViews) {
      return false;
    }
    return userTimmyMeCardsStoredLocally;
  },
  showCardDetails(cardId) {
    window.location = `/timmymecard.html?cardId=${cardId}`;
  },
  generateTimmyMeCardBarCode() {
    const centerTheBarCode = () => {
      $('canvas#barcode-canvas').css('margin', function () {
        const newLeftRightMargin = $(this).parent().width() - $(this).width();
        console.log(newLeftRightMargin) // eslint-disable-line
        return `0 ${newLeftRightMargin / 2}px`;
      });
    };
    bwipjs('barcode-canvas', {
      bcid: 'pdf417',
      text: this.timmyMeCard.shuffledCardNumber,
      scale: window.innerWidth < 480 ? 2 : 3.5,
      height: 10,
      includetext: true,
      textxalign: 'center',
    }, centerTheBarCode);
  },
  updateFontSize() {
    if (this.locale === 'fr') {
      $('p.btn-text').css('font-size', 13);
      return;
    }

    $('p.btn-text').css('font-size', 16);
  },
  getCorrectCoffeeCupImage() {
    // eslint-disable-next-line max-len
    return window.innerWidth > 480 ? './images/desktop-cupgradient.png' : './images/mobile-cupgradient.png';
  },
  logoutUser() {
    window.localStorage.removeItem('THTimmyMeCards');
    window.location = './login.html';
  },
};
