export default (HTTPMethod, resouceURL, data, headers) => new Promise((resolve, reject) => {
  const request = new XMLHttpRequest();
  request.open(HTTPMethod, resouceURL, true);

  if (headers && Object.keys(headers).length) {
    Object.keys(headers).forEach((key) => {
      request.setRequestHeader(key, headers[key]);
    });
  }

  request.onload = () => {
    let response;
    const { status, statusText, responseText, readyState, DONE } = request;
    if (readyState === DONE && status >= 200 && status < 300) {
      try {
        response = responseText !== '' ? JSON.parse(responseText) : {};
      } catch (e) {
        reject(e);
      }

      resolve({ status, statusText, response });
    }

    if (readyState === DONE && status >= 300) {
      reject({ response: responseText, status, statusText });
    }
  };
  request.onerror = (error) => { reject(error); };
  request.send(data ? JSON.stringify(data) : null);
});
