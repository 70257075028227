/* eslint-disable max-len */
export default {
  header: 'Feedback',
  issue: 'Issue',
  issueTypes: {
    Issue_App_Experience: 'App Experience',
    'Issue_In-Restaurant_Experience': 'In-Restaurant Experience',
    Issue_Orders: 'Orders',
    Issue_Payments_and_Account: 'Payments & Account',
    Issue_Promotions: 'Promotions',
    Issue_Suggestions: 'Suggestions',
  },
  message: 'Message',
  mobilePlatform: 'Mobile Platform',
  appVersion: 'App Version (shown in settings)',
  fullnames: 'Full name',
  email: 'Email',
  country: 'Country',
  selectCountry: 'Please select your country',
  postalCode: 'Postal Code',
  submit: 'Send Feedback',
  validation: {
    message: 'Required',
    fullnames: 'Required',
    email: 'Required',
  },
  faqHeader: 'Frequently Asked Questions',
  faqDisclaimer: {
    apple: 'Apple, the Apple logo, Apple Pay, iPhone, and Touch ID are trademarks of Apple Inc., registered in the U.S. and other countries.',
    android: 'Android, Android Pay, and the Android Logo are trademarks of Google Inc.',
    timhortons: 'Tim Hortons® and TimCard® are trademarks of Tim Hortons. ™ & © 2019 Tim Hortons. All rights reserved.',
    lastUpdated: 'Last updated: October 25, 2019',
  },
};
