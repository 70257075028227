/* eslint-disable max-len */
export default {
  bannerText: 'Adoptez notre nouvelle appli et transférez votre solde de MesTim<sup>MC</sup>',
  getIOSapp: 'Téléchargez notre appli pour iOS ',
  getAndroidapp: 'Téléchargez notre appli pour Android',
  viewTimCards: 'Ou affichez vos Cartes TimMD sur Internet',
  loginText: 'MesTim<sup>MC</sup> n\'est plus pris en charge.<br/>Pour vérifier vos soldes,<br/>visitez le <a href="https://timcard.prepaidaccess.com/consumer?locale=fr_CA">site Internet de la Carte Tim<sup>MD</sup></a>.<br/><br/>Téléchargez la dernière application pour gagner des récompenses!',
  username: 'Nom d’utilisateur',
  password: 'Mot de passe',
  login: 'Ouverture de session',
  loginFeedback: 'Nom d’utilisateur ou mot de passe non valide',
  forgotPassword: 'Avez-vous oublié votre mot de passe?',
  timmyMeHeader: 'Mes cartes-cadeaux MesTim<sup>MC</sup> ',
  back: 'Retour',
  showBarcode: 'Afficher le code à barres à la caisse',
  showBarcodeInstructions: 'Vous pouvez afficher ce code à barres sur votre téléphone cellulaire pour « numériser pour payer » vos achats, imprimer ce code à barres pour « numériser pour payer » vos achats ou imprimer ce code à barres, puis le présenter et l’échanger pour une Carte Tim<sup>MD</sup> de plastique en restaurant. Assurez-vous de veiller à la sécurité de votre code à barres.',
};
