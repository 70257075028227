/* eslint-disable max-len */
export default {
  header: {
    slogan: 'Tout ce que vous aimez de Tim Hortons<sup>®</sup> - désormais à portée de main!',
    loyaltyTitle: 'FidéliTim🅪',
    loyaltyUrl: 'https://timhortons.ca/fidelitim',
    feedbackTitle: 'Commentaires',
  },
  features: {
    easier: {
      header: 'Plus facile',
      message: 'Commandez et payez',
    },
    faster: {
      header: 'Plus rapide',
      message: 'Prêt à emporter',
    },
    cheaper: {
      header: 'Plus personnel',
      message: 'Commande répétée facilement',
    },
  },
  terms: 'Conditions d\'utilisation',
  privacy: 'Politique de confidentialité',
  images: {
    playstore: {
      url: './images/fr/tims-micro-androidstore-fr-768-@2x.png.png',
      alt: 'Disponible sur Google Play',
    },
    appstore: {
      url: './images/fr/tims-micro-applestore-fr-768-@2x.png.png',
      alt: ' Télécharger de l’App Store',
    },
    phones: {
      url: './images/timhortonsapp-com-phones-en.png',
      alt: 'Disponible sur iOS et Android',
    },
  },
};
