import enCAFAQContent from './en_ca/FAQContent';
import enCAFeedbackFormContent from './en_ca/feedbackForm';
import enCAHomepageContent from './en_ca/homepage';
import enSubscriptionContent from './en_ca/subscription';
import enGrouponContent from './en_ca/groupon';
import enTimmyMeContent from './en_ca/timmyMe';
import enOffersContent from './en_ca/offers';

import frCAFAQContent from './fr_ca/FAQContent';
import frCAFeedbackFormContent from './fr_ca/feedbackForm';
import frCAHomepageContent from './fr_ca/homepage';
import frSubscriptionContent from './fr_ca/subscription';
import frGrouponContent from './fr_ca/groupon';
import frTimmyMeContent from './fr_ca/timmyMe';
import frOffersContent from './fr_ca/offers';


export {
  enCAFAQContent,
  enCAFeedbackFormContent,
  enCAHomepageContent,
  enSubscriptionContent,
  enGrouponContent,
  enTimmyMeContent,
  enOffersContent,
  frCAFAQContent,
  frCAFeedbackFormContent,
  frCAHomepageContent,
  frSubscriptionContent,
  frGrouponContent,
  frTimmyMeContent,
  frOffersContent,
};
